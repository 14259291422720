@import url('https://fonts.googleapis.com/css2?family=Ovo&display=swap');

@font-face {
    font-family: 'Airbnb Cereal';
    font-style: normal;
    font-weight: 400;
    src: local('Airbnb Cereal App Book'), url('./assets/fonts/AirbnbCerealBook.woff') format('woff');
}

@font-face {
    font-family: 'Airbnb Cereal';
    font-style: normal;
    font-weight: 300;
    src: local('Airbnb Cereal App Light'), url('./assets/fonts/AirbnbCerealLight.woff') format('woff');
}

@font-face {
    font-family: 'Airbnb Cereal';
    font-style: normal;
    font-weight: 500;
    src: local('Airbnb Cereal App Medium'), url('./assets/fonts/AirbnbCerealMedium.woff') format('woff');
}

@font-face {
    font-family: 'Airbnb Cereal';
    font-style: normal;
    font-weight: 700;
    src: local('Airbnb Cereal App Bold'), url('./assets/fonts/AirbnbCerealBold.woff') format('woff');
}

@font-face {
    font-family: 'Airbnb Cereal';
    font-style: normal;
    font-weight: 800;
    src: local('Airbnb Cereal App Extra Bold'), url('./assets/fonts/AirbnbCerealExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Airbnb Cereal';
    font-style: normal;
    font-weight: 900;
    src: local('Airbnb Cereal App Black'), url('./assets/fonts/AirbnbCerealBlack.woff') format('woff');
}

/*
 * Variables
 */

$color_highlighter: #E3BD4B;

$color_blue: #6666FF;
$color_light-blue: #EDEBFF;

$color_green: #61B552;
$color_light-green: #E6FBDA;

$color_orange: #FF7800;

$color_bg-tan: #F9F9F1;
$color_bg-light: #FFF;

$color_font-dark: #000;
$color_font-light: #FFF;

$font_family-primary: 'Ovo', serif;
$font_family-secondary: 'Airbnb Cereal', sans-serif;

$font_size--base: 16px;
$font_size-small: 1rem;     // 16px = 20
$font_size-medium: 1.5rem;  // 24px = 30
$font_size-large: 2rem;     // 32px = 40
$font_size-larger:  2.5rem; // 40px = 50
$font_size-largest: 3.5rem; // 56px = 70
$font_size-huge: 4rem;      // 64px = 80

$box_radius--small: .5rem;
$box_radius: 2.5rem;
$box_shadow: 0 0 50px rgba($color_font-dark, .25);

$dims_padding-small: 1rem; // 16px
$dims_padding-medium: 3rem; // 48px
$dims_padding-large: 5rem; // 80px


/*
 * Mixins + Extends
 */

$mobileBreak: 685px;
$tabletBreak: 1085px;
$largeBreak: 1650px;

@mixin respond-to($media) {
  @if $media == mobile {
    @media all and (max-width: $smallBreak) { @content; }
  }
  @else if $media == tablet {
    @media all and (min-width: $mobileBreak+1px) { @content; }
  }
  @else if $media == desktop {
    @media all and (min-width: $tabletBreak+1px) { @content; }
  }
  @else if $media == large-desktop {
    @media all and (min-width: $largeBreak) { @content; }
  }
}

._vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


/*
 * Globals
 */

html, body {
  position: relative;
  margin: 0;
  color: $color_font-dark;
  font-family: $font_family-primary;
  font-size: $font_size--base * .8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  scroll-behavior: smooth;

  @include respond-to(desktop) {
      font-size: $font_size--base;
  }
}

*, *:before, *:after {
    position: relative;
    box-sizing: inherit;
    line-height: 1.5em;
}

ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

.sans {
    font-family: $font_family-secondary;
}
