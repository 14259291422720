@import 'core';
@import 'vendor';


.App {
    .heavy-padding {
        padding: $dims_padding-large;

        @include respond-to(tablet) {
            padding: $dims_padding-large $dims_padding-large * 2;
        }

        @include respond-to(desktop) {
            padding: $dims_padding-large $dims_padding-large * 4;
        }
    }

    .tan {
        background: $color_bg-tan;
    }
}


@import './components/animations/animations';
@import './components/Blob/Blob';
@import './components/Outline/Outline';
@import './components/Page/Page';
@import './components/Splotch/Splotch';
@import './components/ContentPanel/ContentPanel';
@import './components/DoubleImage/DoubleImage';
@import './components/ImageWrap/ImageWrap';
@import './components/Index/Index';
@import './components/Index/OfficeStatus';
@import './components/Index/Search';
@import './components/Letter/Letter';
@import './components/Parallax/Parallax';
@import './components/Section/Section';
@import './components/QA/QA';
@import './components/issues/Issue/Issue';
@import './components/issues/FloatingImage/FloatingImage';
@import './components/issues/First/FirstIssue';
@import './components/issues/Second/SecondIssue';
@import './components/issues/Three/ThirdIssue';
@import './components/issues/Four/FourthIssue';
@import './components/issues/Five/FifthIssue';
