.FifthIssue {

    .header-circle-wrapper {
        z-index: 10;
    }

    h3 {
        font-size: $font_size-large !important;

        @include respond-to(tablet) {
            font-size: $font_size-largest !important;
        }
    }

    section + section {
        padding-top: 5rem;
        padding-bottom: 4rem;

        @include respond-to(tablet) {
            padding: 7.5rem;
        }
    }

    header h1 {
        margin-top: -12rem !important;
    }

    ol {
        padding-left: 6rem;
        list-style: circle;
    }

    .heavy-padding {
        padding-top: 0;
        padding-bottom: 0;

        .DoubleImage {
            width: 80%;
            margin: auto;
        }
    }

    .banner {
        width: auto;

        z-index: 1;

        .sans {
            font-size: $font_size-medium;
            margin: 20vh auto 10vh;
            padding: 0 3rem;

            @include respond-to(tablet) {
                padding: 0 5rem;
            }
        }
    }

    .lone-header {
        padding-bottom: 0;
        padding-left: 2rem;
        padding-right: 2rem;

        + .Section {
            padding-top: 0;
        }

        p {
            font-weight: 700;
            font-size: $font_size-medium;
        }
    }

    .side-by-side {
        margin: $dims_padding-large 2rem;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        @include respond-to(tablet) {
            left: 35%;

            width: 70%;
            margin: $dims_padding-large * 3 0;
        }

        font-size: $font_size-medium;

        .DoubleImage {
            position: absolute;
            left: -60%;
            top: 0;

            width: 40%;
        }

        &:nth-of-type(2n+1) {
            left: 0;

            .DoubleImage {
                left: 110%;
            }
        }

        ul {
            list-style: none;
            border-left: 4px solid #333;
        }
    }

    .equity {
        ul {
            list-style: initial;
        }
    }

    .strategies {
        .side-by-side {
            &:nth-of-type(3),
            &:nth-of-type(4) {
                .DoubleImage {
                    top: -10vw;
                }
            }

            .sans {
                font-weight: 700;
            }

            ul {
                list-style: initial;
                border-left: 0;
                font-family: $font_family-secondary;
            }
        }
    }

    .more-strategies {
        .side-by-side {
            @include respond-to(tablet) {
                left: 35%;

                width: 70%;
                margin: $dims_padding-medium  0;
            }

            .sans {
                font-weight: 700;
            }

            .DoubleImage {
                top: -500%;
            }

            em {
                font-style: italic;
                background: none !important;
            }
        }
    }

    .stuff {
        .side-by-side {
            @include respond-to(tablet) {
                left: 35%;

                width: 70%;
                margin: $dims_padding-medium  0;
            }

            ul {
                list-style: initial;
                border-left: none;

                strong {
                    background: none !important;
                }
            }

            .sans {
                font-weight: 700;

                ul {
                    font-weight: 400;
                }
            }

            .DoubleImage {
                left: -60%;
            }
        }

        h4 {
            display: inline-block;

            margin-bottom: 0;
            padding-bottom: 3px;

            font-size: $font_size-large * 1.1;
            font-family: $font_family-secondary;

            border-bottom: 5px solid $color_font-dark;

            @include respond-to(tablet) {
                left: -3rem;
                font-size: $font_size-largest * .8;
            }
        }
    }

    .second-image {
        top: 500px !important;
    }

    .waving-kid-friend {
        position: absolute;
        width: 38%;
        right: -20%;
        top: 20%;
    }


}
