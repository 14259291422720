.Issue {
    background: $color_bg-tan;
    overflow: hidden;

    header {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: $dims_padding-medium;

        min-height: 100vh;
        font-size: $font_size-huge;

        background: $color_bg-tan;

        @include respond-to(tablet) {
            padding: $dims_padding-large * 2;
        }

        div {
            flex: 0 1 auto;
            margin: auto;
        }

        img {
            width: 100%;
            &:not(.sasaki-logo) {
                mix-blend-mode: darken;
            }
        }

        .sasaki-logo {
            display: block;
            width: 50%;
            text-align: center;
            margin: auto;
        }

        h1 {
            z-index: 1;
        }

        .header-circle {
            z-index: 0;
            position: absolute;
            top: -70%;

            img {
                display: none;

                @include respond-to(tablet) {
                    display: block;
                }

                width: 130%;
                left: -15%;
            }
        }

    }

    h1 {
        margin: 0;
        margin-bottom: $dims_padding-large;

        font-weight: 400;
        font-size: $font_size-larger;
        font-family: $font_family-secondary;
        text-align: center;
    }

    footer {
        font-family: $font_family-secondary;

        h2 {
            text-align: center;
            font-size: $font_size-larger;
            font-weight: 400;

            margin-bottom: $dims_padding-large;

            @include respond-to(tablet) {
                margin-bottom: $dims_padding-large * 2;
            }
        }

        p {
            font-size: $font_size-medium;
            text-align: center;
        }
    }

}
