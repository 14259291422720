section {
    padding: $dims_padding-medium $dims_padding-small;

    @include respond-to(tablet) {
        padding: $dims_padding-medium;
    }

    @include respond-to(desktop)  {
        padding: $dims_padding-large;
    }

    + section {
        padding: $dims_padding-large * 1.5 $dims_padding-small;

        @include respond-to(tablet) {
            padding: $dims_padding-large * 1.5 $dims_padding-medium;
        }

        @include respond-to(desktop) {
            padding: $dims_padding-large * 1.5 $dims_padding-large;
        }
    }

    &.wide {
        .section-content {
            max-width: 86rem;
        }
    }

    &.heavy-padding {
        padding: $dims_padding-large $dims_padding-small;

        .section-content {
            padding: 0 $dims_padding-small;
        }

        @include respond-to(tablet) {
            padding: $dims_padding-large $dims_padding-medium;

            .section-content {
                padding: 0 $dims_padding-medium;
            }
        }

        @include respond-to(desktop) {
            padding: $dims_padding-large;

            .section-content {
                padding: 0 $dims_padding-large * 2;
            }
        }
    }

    .section-content {
        max-width: 78rem;
        margin: auto;

        &, .FadeUp {
            > h3 {
                font-family: $font_family-secondary;
                font-size: $font_size-largest;
                font-weight: 400;
                text-align: center;
            }

            > p {
                font-size: $font_size-medium;
            }

            img {
                width: 100%;
            }
        }
    }

    &.sub {
        .section-content {
            &, .FadeUp {
                > h3 {
                    font-size: $font_size-larger;
                    margin-bottom: $dims_padding-medium;
                }

                p {
                    padding: 0 $dims_padding-medium;
                    font-size: $font-size-medium;

                    @include respond-to(tablet) {
                        padding: 0 $dims_padding-large * 1.5;
                    }

                    @include respond-to(desktop) {
                        padding: 0 $dims_padding-large * 3;
                    }

                    + p {
                        margin-top: $dims_padding-medium;
                    }
                }

                strong {
                    font-family: $font_family-secondary;
                    font-weight: 700;
                }
            }
        }
    }


}
