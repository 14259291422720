.FourthIssue {

    .header-circle-wrapper {
        z-index: 10;
    }

    h3 {
        font-size: $font_size-large !important;

        @include respond-to(tablet) {
            font-size: $font_size-largest !important;
        }
    }

    section + section {
        @include respond-to(tablet) {
            padding: 7.5rem;
        }
    }

    .banner {
        width: auto;

        z-index: 1;

        .back {
            display: none;
        }

        .sans {
            font-size: $font_size-medium;
            margin: 20vh auto 10vh;
            padding: 0 3rem;

            @include respond-to(tablet) {
                padding: 0 5rem;
            }
        }
    }

    .stuff {
        font-size: $font_size-medium;

        &-part {
            @include respond-to(tablet) {
                display: flex;
            }

            margin: $dims_padding-large * 4 0;

            &-col {
                display: flex;
                flex: 1 1;
                align-items: center;

                &:first-of-type {
                    display: block;
                    flex: 1 1 50%;
                    padding: 0 3rem;
                    margin-bottom: 3rem;

                    @include respond-to(tablet) {
                        padding: 0;
                        padding-right: $dims_padding-large;
                        margin-bottom: 0;
                    }
                }
            }

            &:last-of-type {
                margin-bottom: $dims_padding-large;
            }
        }
    }

    .side-by-sides {
        .side-by-side {
            margin: $dims_padding-large * 2 2rem;

            @include respond-to(tablet) {
                left: 35%;

                width: 70%;
                margin: $dims_padding-large * 3 0;
            }

            font-size: $font_size-medium;

            h4 {
                @include respond-to(tablet) {
                    left: -$dims_padding-large;
                }
            }

            .DoubleImage {
                position: absolute;
                left: -60%;
                top: 0;

                width: 40%;
            }

            &:nth-of-type(2n+1) {
                left: 0;

                .DoubleImage {
                    left: 110%;
                }
            }

            &:nth-of-type(3),
            &:nth-of-type(9),
            &:nth-of-type(15),
            &:nth-of-type(16) {
                .DoubleImage {
                    width: 50%;
                }
            }

            &:nth-of-type(7) {
                .DoubleImage {
                    width: 30%;
                    top: -100%;
                }
            }

            &:nth-of-type(8) {
                .DoubleImage {
                    width: 60%;
                    left: -65%;
                }
            }

            &:nth-of-type(11) {
                .DoubleImage {
                    width: 60%;
                    left: 105%;
                }
            }

            ul {
                list-style: none;
                border-left: 4px solid #333;
            }
        }
    }

    .intro {
        padding: $dims_padding-large $dims_padding-medium $dims_padding-large;
        overflow: hidden;

        @include respond-to(desktop) {
            padding: $dims_padding-large $dims_padding-large $dims_padding-large * 2;
        }

        li {
            max-width: 85.5rem;
            margin: auto;

            font-size: $font_size-medium;

            .DoubleImage {
                transform: translateY(-15%);
            }

            + li {
                margin-top: $dims_padding-medium;
            }

            &:nth-of-type(2n) {
                .Curate__content {
                    flex-direction: row-reverse;
                }
            }

            &.intro-3,
            &.intro-7 {
                .DoubleImage img {
                    width: 55%;
                    margin: auto;
                    left: 20%;
                }
            }

            .Curate__content {
                display: block;
                width: 100%;

                @include respond-to(tablet) {
                    display: flex;
                }

                > div {
                    flex: 1 1 50%;
                    font-family: $font_family-secondary;

                    @include respond-to(tablet) {
                        width: 50%;
                    }
                }

                img {
                    width: 80%;
                }

                p, .Henshu__EditableRichText {
                    margin: auto;
                    padding-bottom: 10% !important;
                }
            }
        }
    }

    .saw-friend {
        position: absolute;
        left: -20%;
        width: 45%;
        top: 30vh;
    }

    .turkey-friend {
        position: absolute;
        left: 80%;
        width: 35%;
        top: 35vh;
    }

}
