@use 'sass:math';

.Level {
    display: flex;
    flex-direction: column;

    margin-top: $dims_padding-small;

    background: $color_bg-light;
    border-radius: $box_radius;
    overflow: hidden;

    &.active {
        .progress .bar {
            background: $color_light-green;
        }
    }

    &.open {
        main {
            display: block;

            @include respond-to(tablet) {
                display: flex;
            }
        }
    }

    header {
        display: flex;
        flex-direction: row;
        flex: 0 1 min-content;
        height: min-content;
        gap: $dims_padding-small;

        padding: $dims_padding-small / 2;

        > * {
            flex: 1;
        }

        font-size: $font_size-medium;

        button {
            display: flex;
            align-items: center;
            flex: 0;

            padding: 0 1rem;
            font-size: $font_size-medium;

            background: none;
            border: none;
            cursor: pointer;
        }
    }

    .title {
        flex: 1 1 auto;
        font-family: $font_family-secondary;

        @include respond-to(tablet) {
            flex: 0 1 5.5em;
        }
    }

    .progress {
        flex: 0 1 max(100px, 15%);
        padding: $dims_padding-small / 2 0;

        .bar {
            width: 100%;
            height: 100%;
            background: $color_light-blue;
            border-radius: $box_radius;
            overflow: hidden;
        }

        span {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            height: 100%;
            background: $color_green;
            transition: width .15s;
        }
    }

    main {
        flex: 1;
        display: none;

        padding: $dims_padding-medium;

        > div {
            @include respond-to(tablet) {
                flex: 1 0 max(10em, 35%);
            }

            ul {
                margin-left: $dims_padding-small;

                font-size: $font_size-small;
                font-family: $font_family-secondary;

                list-style: '- ';
            }

            li {
                opacity: .5;
                color: $color_font-dark;

                &.active {
                    color: #0001FF;
                    opacity: 1;
                }

                &.semi {
                    color: $color_orange;
                    opacity: 1;
                }
            }

            a {
                display: block;

                margin: $dims_padding-medium 0;
                width: max-content;
                padding: math.div($dims_padding-small, 2) $dims_padding-small;

                border-radius: $box_radius;

                color: $color_font-light;
                font-size: $font_size-small;
                font-family: $font_family-secondary;

                background: #0001FF;

                &:hover {
                    opacity: .8;
                }
            }
        }

        .img {
            flex: 1 1 auto;
        }

        img {
            width: 100%;
        }
    }

    .icons {
        display: none;
        gap: $dims_padding-small;
        align-items: center;

        @include respond-to(tablet) {
            display: flex;
        }

        svg {
            cursor: pointer;
        }
    }

    svg {
        color: rgba($color_font-dark, .5);

        &.active {
            color: $color_blue;
        }

        &.semi {
            color: $color_orange;
        }
    }
}
