.DoubleImage {

    &.editing {
        > * {
            display: inline-block;
            width: calc(50% - #{$dims_padding-small / 2});
            height: 100%;

            + * {
                margin-left: $dims_padding-small;
            }
        }
    }

    &:not(.editing) {
        .ImageWrap {
            width: 100%;
            height: 100%;
        }

        .front {
            z-index: 1;
            height: 100%;
        }

        .back {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }
    }

}
