.QA {
    margin-top: $dims_padding-large;

    p {
        margin: 0;

        font-weight: 700;
    }

    .Q {
        left: -5vw;

        font-weight: 700;
    }

    .A {
        right: -5vw;

        span {
            font-weight: 400;
        }
    }

}
