@import './components/MapSequence/MapSequence';

.ThirdIssue {

    .gif {
        margin: $dims_padding-large auto;
    }

    .header-circle-wrapper {
        z-index: 10;
    }

    .banner {
        width: auto;
        max-height: 100vh;

        z-index: 1;

        .back {
            display: none;
        }
    }

    .intro-body {
        margin-top: $dims_padding-large * 4;
        font-family: $font_family-secondary;

        @include respond-to(desktop) {
            width: 50%;
            left: 10%;
        }
    }

    .intro {
        padding: $dims_padding-large $dims_padding-medium $dims_padding-large;
        overflow: hidden;

        @include respond-to(desktop) {
            padding: $dims_padding-large $dims_padding-large $dims_padding-large * 2;
        }

        li {
            max-width: 85.5rem;
            margin: auto;

            font-size: $font_size-medium;

            .DoubleImage {
                transform: translateY(-15%);
            }

            + li {
                margin-top: $dims_padding-medium;
            }

            &:nth-of-type(2n) {
                .Curate__content {
                    flex-direction: row-reverse;
                }
            }

            .Curate__content {
                display: block;
                width: 100%;

                @include respond-to(tablet) {
                    display: flex;
                }

                > div {
                    flex: 1 1 50%;
                    font-family: $font_family-secondary;

                    @include respond-to(tablet) {
                        width: 50%;
                    }
                }

                img {
                    width: 80%;
                }

                p, .Henshu__EditableRichText {
                    margin: auto;
                    padding-bottom: 10% !important;
                }
            }
        }
    }

    .fun-facts-1 {
        .fun-facts-content {
            width: 75%;

            @include respond-to(desktop) {
                width: 36%;
            }

            div + div {
                margin-top: $dims_padding-large;
            }
        }
    }

    .fun-facts-2 {
        li {
            &:nth-of-type(3) {
                margin-top: $dims_padding-large * 2;

                .back {
                    display: none;
                }
            }
        }
    }

    .walking-friends {
        position: absolute;
        left: 65%;
        top: -5%;
        width: 40%;
    }

    .pouring-friends {
        position: absolute;
        left: 30%;
        bottom: -20%;
        width: 55%;
    }

    .train-friend {
        position: absolute;
        right: -22%;
        top: 0;
        width: 40%;
    }

    .noodle-friend {
        position: absolute;
        right: 0%;
        top: 25%;
        width: 25%;
    }

    .swan-friend {
        position: absolute;
        left: -5%;
        top: 45%;
        width: 40%;
    }

    .driving-friend {
        position: absolute;
        right: -10%;
        top: 55%;
        width: 40%;
    }

    .ufo-friend {
        position: absolute;
        right: -13%;
        top: 70%;
        width: 33%;
    }

    .dumpling-friend {
        position: absolute;
        left: -5%;
        bottom: -5%;
        width: 35%;
    }

    .footnotes {
        font-size: $font_size-medium;
        padding: $dims_padding-small;
        text-align: center;

        &-note {
            top: -10vh;
            padding: 0;
            text-align: center;
            font-style: italic;
        }

        &-content {
            max-width: 800px;
            margin: auto;
        }

        a {
            color: $color_font-dark;
            text-decoration: underline;
            opacity: 1;
            transition: opacity .15s;

            &:hover {
                opacity: .6;
            }
        }

        li + li {
            margin-top: $dims_padding-small;
        }
    }

}
