.Letter {
    padding: $dims_padding-small $dims_padding-small $dims_padding-medium;

    background: none;
    border-radius: $box_radius;
    box-shadow: $box_shadow;

    &.heavy-padding {
        padding-left: $dims_padding-large * 2;
        padding-right: $dims_padding-large * 2;
    }

    @include respond-to(tablet) {
        padding: $dims_padding-small $dims_padding-large $dims_padding-medium;

        &.heavy-padding {
            padding-left: $dims_padding-large * 2;
            padding-right: $dims_padding-large * 2;
        }
    }

    &.single-image {
        .DoubleImage {
            .Parallax:nth-of-type(2) {
                display: none;
            }
        }
    }

    .DoubleImage {
        width: 75%;
        margin: auto;
    }

    .body {
        margin-top: -$dims_padding-medium;
        font-size: $font_size-medium;
        overflow: hidden;

        &:not(.expanded) {
            max-height: 50vh;

            &:before {
                content: '';
                display: block;
                z-index: 1;
                height: 180px;
                position: absolute;
                width: 110%;
                left: -5%;
                bottom: 0px;
                background: linear-gradient(to top, #{$color_bg-tan} 30%, rgba($color_bg-tan, 0));
            }
        }

        > button {
            position: absolute;
            right: 0;
            margin: auto;
            bottom: 0;
            z-index: 2;
            padding: 1.25rem;

            color: $color_font-dark;
            font-size: $font_size-medium;
            font-weight: 500;
            line-height: .9em;

            background: $color_bg-light;
            border: none;
            border-radius: 2.5rem;
            box-shadow: 0 0 .5rem rgba(#050038, .4) inset;
            cursor: pointer;

            &:hover {
                background: $color_bg-tan;
            }
        }
    }
}
