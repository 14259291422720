._FadeUp {
    opacity: 0;
    transform: translateY(80px);
    transition: transform 1s, opacity .9s;

    &.active {
        opacity: 1;
        transform: translateY(0px);
    }
}

.FadeUp {
    @extend ._FadeUp;

    .Anchor {
        position: absolute;
        top: -10vh;
    }
}
