.FirstIssue {
    .intro {
        padding: $dims_padding-large $dims_padding-medium $dims_padding-large;
        overflow: hidden;

        @include respond-to(desktop) {
            padding: $dims_padding-large $dims_padding-large $dims_padding-large * 2;
        }

        li {
            max-width: 85.5rem;
            margin: auto;

            font-size: $font_size-medium;

            .DoubleImage {
                transform: translateY(-15%);
            }

            + li {
                margin-top: $dims_padding-medium;
            }

            &:nth-of-type(1) {
                @include respond-to(tablet) {
                    width: calc(100% - #{$dims_padding-large});
                }
                .FadeUp div {
                    max-width: 52.065rem;
                }
            }

            &:nth-of-type(2) {
                left: 0%;
                padding: $dims_padding-large * 2 0;

                .back {
                    display: none;
                }
            }

            &:nth-of-type(3) {
                @include respond-to(tablet) {
                    left: 10%;
                }

                .FadeUp {
                    flex-direction: row-reverse;
                }

                .back {
                    display: none;
                }
            }

            &:nth-of-type(4) {
                @include respond-to(tablet) {
                    .Henshu__EditableRichText {
                        padding: $dims_padding-large 0;
                        margin-left: $dims_padding-large;
                    }
                }
            }

            &:nth-of-type(5) {
                .FadeUp {
                    flex-direction: row-reverse;
                }

                @include respond-to(tablet) {
                    .Henshu__EditableRichText {
                        padding: $dims_padding-large 0;
                        margin-right: $dims_padding-large;
                    }
                }

                @include respond-to(desktop) {
                    left: 5%;
                }
            }

            &:nth-of-type(6) {
                left: 0%;
            }


            .FadeUp {
                display: block;
                width: 100%;

                @include respond-to(tablet) {
                    display: flex;
                }

                > div {
                    flex: 1 1 50%;
                    font-family: $font_family-secondary;

                    @include respond-to(tablet) {
                        width: 50%;
                    }
                }

                img {
                    width: 100%;
                }
            }
        }
    }

    .letters {
        .section-content {
            > .Curate {
                + .Curate {
                    margin-top: $dims_padding-large;
                }
            }
        }
    }

    .places-to-work {
        margin: $dims-padding-large auto;

        font-family: $font_family-secondary;
        font-size: $font_size-small;

        @include respond-to(tablet) {
            margin: $dims-padding-large * 2 auto;
            font-size: $font_size-medium;
        }

        ul {
            display: grid;
            grid-gap: $dims_padding-medium;
            grid-template-columns: 1fr 1fr 1.2fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            grid-auto-flow: column;

            padding: $dims_padding-medium;

            @include respond-to(tablet) {
                grid-gap: $dims_padding-large;
                padding: 0;
            }
        }

        li {
            @extend ._FadeUp;
            $delay: .085s;

            span {
                display: inline-block;
                padding: 1.5em 2.5em;
            }

            .Blob {
                z-index: -1;
            }

            &:nth-of-type(1) {
                top: 0;
                left: 0;
            }

            &:nth-of-type(2) {
                top: 0;
                @include respond-to(tablet) {
                    left: 60%;
                }

                transition-delay: $delay;
            }

            &:nth-of-type(3) {
                top: 0;
                @include respond-to(tablet) {
                    left: 20%;
                }

                transition-delay: $delay*1;
            }

            &:nth-of-type(4) {
                top: 0;
                @include respond-to(tablet) {
                    left: 10%;
                }

                transition-delay: $delay*2;
            }

            &:nth-of-type(5) {
                @include respond-to(tablet) {
                    top: 50%;
                    left: 10%;
                }
                transition-delay: $delay*5;
            }

            &:nth-of-type(6) {
                @include respond-to(tablet) {
                    top: 35%;
                    left: 50%;
                }
                transition-delay: $delay*4;
            }

            &:nth-of-type(7) {
                @include respond-to(tablet) {
                    top: 30%;
                    left: 0%;
                }
                transition-delay: $delay*3;
            }

            &:nth-of-type(8) {
                visibility: hidden;
            }

            &:nth-of-type(9) {
                top: 0;
                left: 0;
                transition-delay: $delay*6;
            }

            &:nth-of-type(10) {
                top: 0;
                @include respond-to(tablet) {
                    left: 60%;
                }
                transition-delay: $delay*7;
            }

            &:nth-of-type(11) {
                top: 0;
                @include respond-to(tablet) {
                    left: 20%;
                }
                transition-delay: $delay*8;
            }

            &:nth-of-type(12) {
                top: 0;
                @include respond-to(tablet) {
                    left: 10%;
                }
                transition-delay: $delay*9;
            }
        }
    }

    .metrics {
        .FadeUp {
            .Anchor {
                top: -20vh;
            }

            + .FadeUp {
                margin-top: $dims_padding-medium * .9;

                @include respond-to(desktop) {
                    margin-top: $dims_padding-medium;
                }
            }
        }

        li {
            width: 100%;

            &.right {
                .metric-content {
                    text-align: left;
                    left: 40%;

                    flex-direction: row-reverse;
                }

                span {
                    margin-right: 0;
                    margin-left: $dims_padding-small;

                    @include respond-to(tablet) {
                        margin-left: $dims_padding-medium;
                    }
                }
            }
        }

        .metric-content {
            display: flex;
            align-items: center;

            width: 60%;
            left: -5%;

            text-align: right;
        }

        span {
            flex: 1 1 auto;
            font-size: $font_size-medium;
            margin-right: $dims_padding-small;

            @include respond-to(tablet) {
                margin-right: $dims_padding-medium;
            }
        }

        b {
            flex: 0 0 auto;
            min-width: 25%;
            text-align: center;
            font-size: $font_size-larger;
            font-weight: 500;
            font-family: $font_family-secondary;

            @include respond-to(tablet) {
                font-size: $font_size-huge;
            }
        }
    }

    .features-section {
        padding: 0;

        .section-content {
            max-width: unset;
        }
    }

    .features {
        overflow: hidden;
        margin-bottom: -$dims_padding-small;
        padding-bottom: $dims_padding-medium;

        .Curate {
            &:nth-of-type(2n) {
                .ImageWrap {
                    right: unset;
                    left: 0;
                }

                p {
                    @include respond-to(tablet) {
                        left: 62%;
                    }
                }
            }

            + .Curate {
                margin-top: $dims_padding-medium;

                @include respond-to(tablet) {
                    margin-top: $dims_padding-large;
                }
            }
        }

        li {
            width: calc(100% - #{$dims_padding-medium});
            height: auto;
            margin: 0 auto;
            transition: width .6s, height 1s;

            @include respond-to(tablet) {
                width: calc(100% - #{$dims_padding-medium * 2});
            }

            @include respond-to(desktop) {
                width: calc(100% - #{$dims_padding-large * 4});
            }

            + li {
                margin-top: $dims_padding-medium;

                @include respond-to(tablet) {
                    margin-top: $dims_padding-large;
                }
            }

            p {
                padding: $dims_padding-large 0;
                margin-top: 100%;

                font-size: $font_size-large;
                opacity: 1;
                transform: translateY(0%);
                transition: opacity 1.2s, transform 1.4s;

                @include respond-to(tablet) {
                    width: 40%;
                    margin-top: auto;
                }

                @include respond-to(desktop) {
                    padding: $dims_padding-large * 2 0;
                }
            }

            .ImageWrap {
                position: absolute;
                top: -100%;
                right: 0;
                width: 100%;
                height: 100%;

                border-radius: $box_radius;
                transition: right .5s, width .5s, height .5s, border-radius .5s;

                @include respond-to(tablet) {
                    top: 0;
                    width: 55%;
                }
            }

            &.inactive {
                width: calc(100% - #{$dims_padding-large});
                height: 100vh;

                .ImageWrap {
                    margin: auto;
                    width: 100%;
                    height: 100vh;
                }

                p {
                    opacity: 0;
                    transform: translateY(5%);
                }
            }
        }
    }

    // Friends
    .library-friend {
        right: -10%;
        width: 35%;
        top: -25%;

        @include respond-to(desktop) {
            right: -10%;
            width: 35%;
            top: -35%;
        }

        .Anchor {
            top: 0;
        }
    }

    .meditating-friend {
        left: -15%;
        width: 40%;
        bottom: -20%;

        .Anchor {
            top: 50px;
        }
    }

    .pinup-friend {
        right: -5%;
        width: 35%;
        top: -10%;

        @include respond-to(tablet) {
            top: -30%;
        }

        @include respond-to(desktop) {
            right: -10%;
        }

        .Anchor {
            top: 0;
        }
    }

    .screen-friends {
        left: -10%;
        width: 40%;
        top: -10%;

        @include respond-to(desktop) {
            left: -10%;
            top: -20%;
        }

        .Anchor {
            top: 0;
        }
    }

    .seats-metric {
        display: none;

        right: -14%;
        width: 35%;
        top: 10%;

        @include respond-to(tablet) {
            display: block;
        }

        @include respond-to(desktop) {
            right: -13%;
            width: 35%;
        }

        .Anchor {
            top: 0;
        }
    }

    .window-friend {
        left: -15%;
        width: 40%;
        bottom: -15%;

        @include respond-to(tablet) {
            left: -20%;
            width: 45%;
            bottom: 0%;
        }

        .Anchor {
            top: 0;
        }
    }

    .model-making-friend {
        right: 0;
        top: -10%;
        width: 35%;
    }

    .phone-friend {
        left: 0;
        top: 25%;
        width: 35%;

        @include respond-to(desktop) {
            top: 15%;
        }
    }

    .whiteboard-friend {
        right: 0;
        top: 45%;
        width: 40%;

        .back {
            display: none;
        }
    }

    .down-dog-friend {
        left: 0;
        top: 75%;
        width: 35%;
    }

    .elf-friend {
        right: -35%;
        width: 75%;
        top: -15%;
    }

    .drawing-friend {
        right: -20%;
        width: 45%;
        top: -15%;
    }

}
