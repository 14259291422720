.Henshu {
    &__EditableRichText {
        strong {
            font-family: $font_family-secondary;
        }

        em {
            font-style: normal;
            background: rgba($color_highlighter, .2) !important;
        }

        ul {
            list-style: disc;
            padding-left: 1.5em;
        }

        a {
            color: #b75656 !important;
        }
    }
}

