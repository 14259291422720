.ContentPanel {
    padding: $dims_padding-large $dims_padding-medium;

    @include respond-to(tablet) {
        padding: $dims_padding-large;
    }

    @include respond-to(desktop) {
        padding: $dims_padding-large $dims_padding-large * 2;
    }

    h3 {
        margin: 0 auto 1.2em;
        font-family: $font_family-secondary;
        font-size: $font_size-largest;
        text-align: center;
    }

    .body {
        font-size: $font_size-medium;
    }

    h4 {
        margin: $dims_padding-large auto 0;

        font-family: $font_family-secondary;
        font-size: $font_size-medium;
        font-weight: 400;
        text-align: center;
    }

    + .ContentPanel {
        margin-top: $dims_padding-large;
    }

    &.quoted {
        $_size: 200px;
        $_size-small: 100px;

        .body {
            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                width: $_size-small;
                height: $_size-small;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                z-index: -1;

                @include respond-to(desktop) {
                    width: $_size;
                    height: $_size;
                }
            }

            &:before {
                left: -$_size-small / 2;
                top: -$_size-small;

                @include respond-to(desktop) {
                    left: -$_size / 2;
                }
            }

            &:after {
                right: -$_size-small / 3;
                bottom: -$_size-small / 2;

                @include respond-to(tablet) {
                    right: -$_size-small / 2;
                    bottom: -$_size-small;
                }

                @include respond-to(desktop) {
                    right: -$_size / 2;
                }
            }
        }

        &, &.green {
            .body {
                &:before {
                    background-image: url(../../assets/images/green-open-quote.png);
                }
                &:after {
                    background-image: url(../../assets/images/green-close-quote.png);
                }
            }
        }
        &.pink {
            .body {
                &:before {
                    background-image: url(../../assets/images/pink-open-quote.png);
                }
                &:after {
                    background-image: url(../../assets/images/pink-close-quote.png);
                }
            }
        }
        &.gold {
            .body {
                &:before {
                    background-image: url(../../assets/images/gold-open-quote.png);
                }
                &:after {
                    background-image: url(../../assets/images/gold-close-quote.png);
                }
            }
        }

    }

}
