@use '~@strategies/tokens/layout';


.Index {

    .banner {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        width: 100%;
        padding: $dims_padding-small / 2;

        color: $color_blue;
        font-size: $font_size-small;
        font-family: $font_family-secondary;
        text-align: center;

        background: #E5E5FF;
        cursor: pointer;
        box-shadow: layout.$box-shadow;

        &:hover {
            text-decoration: underline;
        }
    }

    .section-content {
        position: static;
    }

    .anchor {
        position: absolute;
        top: 0;
    }

    > header {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;

        > a {
            flex: 0;
            top: 0;

            margin-bottom: 5vh;
            font-size: $font_size-large;
            color: $color_blue;
            transition: top .15s;
            cursor: pointer;

            &:hover {
                top: 2px;
                transition: top .15s;
            }
        }

        section {
            flex: 1;

            .section-content {
                display: block;
                flex-direction: column;
                align-items: center;
                margin: auto;

                @include respond-to(tablet) {
                    display: flex;
                }

                > div {
                    display: block;

                    @include respond-to(tablet) {
                        display: flex;
                    }

                    img {
                        flex: 1 1 min-content;
                    }
                }
            }
        }

        ul {
            flex: 1 0 max-content;

            text-align: center;

            font-size: $font_size-medium;

            @include respond-to(tablet) {
                margin-left: $dims_padding-large;
            }

            li {
                align-items: center;
            }

            svg {
                flex: 1 1 min-content;
                color: $color_blue;
                margin: auto;
                margin-right: $dims_padding-small;
                vertical-align: middle;
            }

            a {
                margin: auto;
                vertical-align: middle;
                flex: 1 1;
                text-decoration: underline;
                color: $color_blue;
            }
        }
    }

    .contact {
        padding-top: 0;
        text-align: center;
        font-size: $font_size-medium;
        max-width: 60rem;
        margin: auto;
    }

    hgroup {
        display: block;
        align-items: center;
        gap: $dims_padding-medium;

        margin: $dims_padding-large auto;
        width: max-content;

        @include respond-to(tablet) {
            display: flex;
        }

        img {
            max-width: 28rem;
            width: 100%;
        }

        h1 {
            flex: 1 0;
            padding: 0;
            max-width: 28rem;

            text-align: center;

            @include respond-to(tablet) {
                text-align: left;
                margin: 0;
            }
        }
    }

    h1, h2 {
        font-family: $font_family-secondary;
        font-size: $font_size-larger;
        font-weight: 400;
    }

    h2 {
        text-align: center;
    }

    .office {
        .section-content {
            > p {
                text-align: center;
                font-family: $font_family-secondary;
                color: rgba($color_font-dark, .5);
                font-size: $font_size-small;
                text-transform: uppercase;
                margin-bottom: $dims_padding-medium;
            }
        }
    }

    .previous {
        .section-content > div {
            margin-top: $dims_padding-large;
            display: flex;
            gap: $dims_padding-medium;
            flex-wrap: wrap;
            align-items: center;

            a {
                display: block;
                flex: 0 1 100%;
                height: 180px;
                width: auto;
                margin: auto;

                opacity: 1;
                transition: opacity .15s;
                text-align: center;

                @include respond-to(tablet) {
                    flex: 0 1 min-content;
                }

                img {
                    max-width: 100%;
                    width: auto;

                    @include respond-to(tablet) {
                        max-width: initial;
                    }

                    height: 100%;
                    margin: auto;
                }

                &:hover {
                    opacity: .7;
                    top: 2px;
                }
            }
        }
    }

    .search {
        padding-top: 0;

        .col {
            align-items: center;

            @include respond-to(tablet) {
                display: flex;
            }

            p {
                font-size: $font_size-small * .8;
                flex: 1 1 50%;
                font-family: $font_family-secondary;

                @include respond-to(tablet) {
                    text-align: right;
                }
            }
        }

        h2 {
            font-size: $font_size-medium;
            font-weight: 600;
            flex: 1;
            text-align: left;
        }
    }

    .questions {
        padding-top: 0;

        .section-content {
            @include respond-to(tablet) {
                padding: $dims_padding-large;
            }

            > div {
                > h3 {
                    font-size: $font_size-medium;
                }

                margin-bottom: $dims_padding-medium;

                > div {
                    padding-left: $dims_padding-small;

                    h3 {
                        font-size: $font_size-small;
                        margin: 0;
                    }

                    p, ul {
                        font-size: $font_size-small;
                        margin: 0;
                        margin-bottom: $dims_padding-small;
                        padding-left: $dims_padding-small;
                    }
                }
            }
        }
    }

    footer {
        width: 100%;

        section {
            padding-bottom: 0 !important;
        }

        .section-content {
            border-top: 1px solid $color_font-dark;
            position: relative;
        }

        .Henshu__EditableRichText {
            margin: $dims_padding-large 0;
        }

        p {
            margin: 0;
            font-family: $font_family-secondary;
        }

        img {
            width: 100%;
            margin-bottom: -10px;
        }

        button {
            display: flex;
            align-items: center;
            position: absolute;
            right: $dims_padding-small;
            top: - $dims_padding-small;

            padding: .35em;

            color: $color_font-light;
            font-size: $font_size-small;

            background: $color_font-dark;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            transition: margin-top .15s;

            &:hover {
                margin-top: -3px;
            }
        }
    }
}
