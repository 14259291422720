.Search {
    em {
        font-style: normal;
        background: $color_highlighter;
    }

    .ais-SearchBox-form {
        display: flex;

        border-radius: $box_radius;
        border: 1px solid $color_font-dark;
        background: $color_font-light;

        overflow: hidden;

        input {
            flex: 1;
            border: none;
            padding: $dims_padding-small / 2 $dims_padding-small;
            background: none;
            outline: none;
        }

        button {
            border: none;
            background: none !important;
            cursor: pointer;
            margin-right: $dims_padding-small;
        }
    }

    .hits {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: $dims_padding-small;
        margin-top: $dims_padding-small;

        @include respond-to(tablet) {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        }

        @include respond-to(desktop) {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        }
    }

    .Hit {
        display: block;
        height: 100%;

        box-shadow: 0 0 0px 0 rgba($color_font-dark, 0);
        border-radius: 20px;
        transform: translateY(0px);
        transition: transform .15s, box-shadow .15s;

        &:hover {
            box-shadow: 0 3px 5px 0 rgba($color_font-dark, .25);
            transform: translateY(-3px);
        }

        a {
            display: flex;
            flex-direction: column;

            height: 100%;
            padding: $dims_padding-small;

            color: $color_font-dark;
            font-family: $font_family-secondary;

            background: $color_font-light;
            border: 1px solid $color_font-dark;
            border-radius: 20px;
            overflow: hidden;

            h3 {
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            main {
                flex: 1 0 auto;
                margin: $dims_padding-small auto;
            }

            footer {
                flex: 0 1 min-content;
                display: flex;
                gap: $dims_padding-small;
                color: rgba($color_font-dark, .5);
            }
        }

        .hit-author {
            flex: 1 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .guide-number {
            flex: 0 0 max-content;
            margin-left: auto;
            margin-right: 0;
        }
    }

    .ais-Pagination-list {
        display: flex;
        color: $color_font-dark;
        gap: $dims_padding-medium;
        margin-top: $dims_padding-small;
        font-family: $font_family-secondary;
        font-size: $font_size-medium;

        a {
            color: $color_font-dark;
        }
    }

    .no-hits {
        margin-top: $dims_padding-medium;
        font-size: $font_size-medium;
        text-align: center;
        font-family: $font_family-secondary;
    }
}
