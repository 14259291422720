@import "components/BuildingStack/BuildingStack";

.SecondIssue {

    .gif {
        margin-top: 4rem;
    }

    .intro-body {
        margin-top: $dims_padding-large * 4;
        font-family: $font_family-secondary;

        @include respond-to(desktop) {
            width: 50%;
            left: 10%;
        }
    }

    .intro {
        padding: $dims_padding-large $dims_padding-medium $dims_padding-large;
        overflow: hidden;

        @include respond-to(desktop) {
            padding: $dims_padding-large $dims_padding-large $dims_padding-large * 2;
        }

        li {
            max-width: 85.5rem;
            margin: auto;

            font-size: $font_size-medium;

            .DoubleImage {
                transform: translateY(-15%);
            }

            + li {
                margin-top: $dims_padding-medium;
            }

            &:nth-of-type(2n+1) {
                .Curate__content {
                    flex-direction: row-reverse;
                }
            }

            .Curate__content {
                display: block;
                width: 100%;

                @include respond-to(tablet) {
                    display: flex;
                }

                > div {
                    flex: 1 1 50%;
                    font-family: $font_family-secondary;

                    @include respond-to(tablet) {
                        width: 50%;
                    }
                }

                img {
                    width: 80%;
                }

                p, .Henshu__EditableRichText {
                    margin: auto;
                    padding-bottom: 10% !important;
                }
            }
        }
    }

    .top-intro li {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(5),
        {
            .back {
                display: none;
            }
        }
    }

    .building {
        margin: 45vh 0 120vh;

        &-header {
            margin-bottom: $dims_padding-large * 3;
        }

        > .FadeUp {
            z-index: 1;
        }

        h3 {
            margin-top: 20rem;
            background: rgba($color_bg-tan, .6);

            text-align: center;
            font-size: $font_size-larger;
            font-family: $font_family-secondary;
            font-weight: 400;
        }
    }

    .building-stack-wrapper {
        position: absolute;
        left: 0;
        height: 100vh;
        width: 100%;
        text-align: center;
        transition: width .5s;
        max-width: 70rem;
        margin: auto;
        z-index: 0;

        @include respond-to(tablet) {
            width: 40%;
        }

        path {
            opacity: 0;
        }

        pattern path {
            opacity: 1;
        }

        &.top {
            top: 0;
        }

        &.fixed {
            position: fixed;
            top: 0;
        }

        &.bottom {
            bottom: -120vh;
            width: 100%;


            .BuildingStack {
                width: 100% !important;
            }

            svg {
                opacity: 0;
                @include respond-to(tablet) {
                    opacity: 1;
                }
                left: 50%;
                transition: left 1s;
            }
        }
    }

    .closer {
        bottom: 30vh;
    }


    .build-section {
        + .build-section {
            margin-top: $dims_padding-large * 2;
        }

        &.collaborate .Anchor {
            &:nth-of-type(2) .build-segment {
                $_color: #C24DFF;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }

            &:nth-of-type(3) .build-segment {
                $_color: #A88CAD;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }

            &:nth-of-type(4) .build-segment {
                $_color: #C24DFF;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }

            &:nth-of-type(5) .build-segment {
                $_color: #C24DFF;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }

            &:nth-of-type(6) .build-segment {
                $_color: #C24DFF;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }

            &:nth-of-type(7) .build-segment {
                $_color: #D1C7D9;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }

            &:nth-of-type(8) .build-segment {
                border-color: #C24DFF;

                em {
                    background: rgba(#D1C7D9, .2);
                }
            }
        }

        &.focus .Anchor {
            &:nth-of-type(4) .build-segment {
                $_color: #57D493;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }
        }

        &.recharge .Anchor {
            &:nth-of-type(3) .build-segment {
                $_color: #D9A300;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }

            &:nth-of-type(4) .build-segment {
                $_color: #915C00;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }

            &:nth-of-type(5) .build-segment {
                $_color: #D9A300;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }

            &:nth-of-type(6) .build-segment {
                $_color: #D9A300;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }
        }

        &.stuff .Anchor {
            &:nth-of-type(3) .build-segment {
                $_color: #FF002E;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }
            &:nth-of-type(4) .build-segment {
                $_color: #FF002E;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }
            &:nth-of-type(5) .build-segment {
                $_color: #FF002E;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }
            &:nth-of-type(6) .build-segment {
                $_color: #FF002E;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }
            &:nth-of-type(7) .build-segment {
                $_color: #FF002E;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }
            &:nth-of-type(8) .build-segment {
                $_color: #FF002E;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }
            &:nth-of-type(9) .build-segment {
                $_color: #B88F8F;
                border-color: $_color;

                em {
                    background: rgba($_color, .2);
                }
            }

        }
    }

    .build-content {
        z-index: 1;
        width: 95%;
        margin: auto;

        @include respond-to(tablet) {
            width: 50%;
            left: 45%;
            padding-right: $dims_padding-large;
            margin: 0;
        }

        .FadeUp .Anchor {
            top: -50vh;
        }

        .Anchor {
            margin-top: 20rem;
        }
    }

    .build-segment {
        padding: $dims_padding-medium;

        font-family: $font_family-secondary;
        font-size: $font_size-medium;

        background: $color_bg-light;
        border-radius: $box_radius--small * 2;
        border: 3px solid $color_font-dark;
        box-shadow: 0 0 25px 0 rgba($color_font-dark,.25);

        * {
            padding: 0;
            margin: 0;
        }
    }

    .top-segment {
        margin-bottom: $dims_padding-large * 4;
    }

    .reserved {
        .Henshu__EditableRichText {
            font-size: $font_size-medium;

            em {
                background: rgba(#D9A301, .7);
            }

            strong {
                &:nth-of-type(3) em {
                    background: rgba(#B88F8F, .8);
                }
                &:nth-of-type(4) em {
                    background: rgba(#1C804D, .7);
                }
                &:nth-of-type(5) em {
                    background: rgba(#8C99C2, .7);
                }
            }
        }
    }

    .booking {
        h2 {
            margin-top: 12rem;

            font-size: $font_size-larger;
            font-family: $font_family-secondary;
            font-weight: 400;
            text-align: center;
        }

        h3 {
            margin-top: 12rem;

            font-size: $font_size-larger !important;
            font-family: $font_family-primary !important;
            font-weight: 400;
        }

        .Outline, .Splotch {
            position: relative;
            width: 380px;
            height: 380px;
        }

        p {
            font-family: $font_family-secondary;
            font-size: $font_size-medium;
            padding: 0 !important;
        }

        .green-outline, .mauve-outline, .gold-disc {
            span {
                font-family: $font_family-secondary;
                font-size: $font_size-medium;
            }
        }

        .intro {
            li .FadeUp {
                display: flex;
            }

            p {
                font-weight: 500;
                width: 50%;
            }

            .DoubleImage {
                width: 40%;
                flex: 1 1 40%;
            }
        }

        &-segment {
            margin-top: $dims_padding-large * 2;

            .Curate__content {
                @include respond-to(tablet) {
                    display: flex;
                }

                text-align: center;
                align-items: center;
            }

            div {
                margin: auto;
                flex: 0 1 380px;
            }

            p {
                position: absolute;
                top: 50%;
                left: -50%;
                right: -50%;
                transform: translateY(-50%);
                text-align: center;

                width: max-content;
                max-width: 75%;
                margin: auto;
                text-shadow: 0 0 3px rgba($color_bg-light, .6);
            }
        }
    }

    .bubble-friend {
        .DoubleImage {
            width: 65%;
            margin: auto;
        }
    }

    .table-friend {
        position: absolute;
        left: -10%;
        width: 40%;
    }

    .phone-friend {
        position: absolute;
        right: -10%;
        top: 0;
        width: 40%;
    }

    .max-friend {
        position: absolute;
        left: -6%;
        width: 45%;

        .back {
            display: none;
        }
    }

    .glass-friend {
        position: absolute;
        right: -10%;
        width: 60%;
    }

    .octo-friend {
        position: absolute;
        right: -10%;
        width: 50%;
        flex: initial !important;

        div {
            flex: initial !important;
        }

        .back {
            display: none;
        }
    }

    .thinking-friend {
        position: absolute;
        left: -15%;
        top: 0;
        width: 50%;
    }

    .abc-friend {
        position: absolute;
        right: -10%;
        top: -50%;
        width: 45%;
        flex: initial !important;

        div {
            flex: initial !important;
        }
    }

    .collab-friend {
        position: absolute;
        right: -10%;
        transform: translateY(-20%);
        width: 30%;
    }

    .scarf-friend {
        position: absolute;
        left: -10%;
        width: 35%;
        top: 10%;
    }

    .handphone-friend {
        position: absolute;
        right: -13%;
        width: 35%;
        top: -100%;
    }

}
