.MapSequence {
    $this: &;
    $_padding: 6vw;

    * {
        pointer-events: none;
    }

    &.editing {
        * {
            pointer-events: all;
        }

        .image-section {
            display: none;
        }

        #{$this}__Panel .AutoSizer {
            display: block !important;
            width: 70%;
        }
    }

    &.sticky {
        .image-section {
            position: fixed;

            #{$this}__Panel {
                .AutoSizer {
                    display: block;
                }

                &:not(.active) svg {
                    pointer-events: none;
                }
            }
        }

        #{$this}__Panel .AutoSizer {
            display: none;
        }
    }

    .image-section {
        position: absolute;
        top: 0;
        width: 100%;
        height: 40vh;
        padding: 0;

        @include respond-to(desktop) {
            padding: inherit;
            height: 80vh;
            background: none;
        }

        #{$this}__Panel {
            position: absolute;

            opacity: 0;
            transition: opacity .5s;

            .content {
                display: none;
            }

            &.active {
                opacity: 1;
                transition: opacity .5s;

                * {
                    pointer-events: all;
                }
            }

            .ReactPinboard__Pin {
                pointer-events: all;
            }
        }
    }

    .panel-wrapper {
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(7),
        &:nth-of-type(9),
        &:nth-of-type(11) {
            #{$this}__Panel h3 {
                display: none;
            }
        }
    }

    &__Panel {
        height: 100vh;
        width: 100%;

        .content {
            @extend ._vertical-align;
            z-index: 1;
            width: 95%;
            margin: auto;
            left: 0;
            right: 0;

            @include respond-to(desktop) {
                width: 100%;
                right: unset;
            }

            h3 a {
                text-decoration: underline;
                color: $color_font-dark;
            }

            h4 {
                text-align: left;
                margin-top: 0;
            }
        }

        .panel {
            width: 100%;
            padding: 1.75rem;

            text-align: center;

            background: rgba($color_bg-light, .75);
            border-radius: $box-radius;
            box-shadow: $box_shadow;

            @include respond-to(desktop) {
                width: 35%;
                left: $_padding;
            }

            * {
                pointer-events: all;
            }

            h3 {
                margin: 0;
                margin-bottom: .2em;
                text-align: left;
                font-size: $font_size-large;
            }

            p {
                margin: 0;
                text-align: left;
                font-size: $font_size-medium;
            }

            img {
                margin: auto;
                max-height: 40vh;
                width: auto;
                max-width: 100%;
            }
        }

        .AutoSizer {
            @extend ._vertical-align;
            left: unset;
            right: 0;
            z-index: 0;

            height: 100%;

            > div {
                width: 100% !important;
                height: 100% !important;
            }

            img {
                width: 100%;
                max-height: 100%;
            }
        }

        .AutoSizer {
            width: 100%;

            left: 0;
            top: 0;
            transform: unset;
            transition: top .5s, width .5s, height .5s;

            @include respond-to(desktop) {
                width: 65%;
                left: unset;
                right: $_padding;
            }
        }

    }

    .ReactPinboard {
        &__Canvas {
            top: 0;

            transform: none;

            @include respond-to(desktop) {
                top: 50%;

                border-radius: $box_radius;

                transform: translateY(-50%);
                transition: border-radius .5s;
            }
        }

        &__Pin {
            width: 1.5vw;

            svg {
                pointer-events: all;
                position: absolute;
                bottom: 0;

                width: 100%;
                height: auto;

                transform: translateX(-50%);
                transition: bottom .1s;

                &:hover {
                    bottom: 2px;
                    cursor: pointer;
                    transition: bottom .1s;
                }
            }
        }
    }

}
