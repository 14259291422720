.BuildingStack {
    $duration: .7s / 7;

    position: absolute;
    left: -50%;
    right: -50%;
    height: 100vh;
    margin: auto;
    top: -2vh;
    transition: width .5s;

    div.floor {
        width: 100%;
        position: absolute;
        top: 0;
        opacity: 1;
        transition: top 1s linear, opacity .3s;
        transition-duration: $duration * 8;
        transition-delay: $duration;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            * {
                transition: opacity .15s;
            }

            .inactive {
                opacity: 0;
            }

            .active {
                opacity: 1;
            }

            .visited {
                opacity: .35;
            }
        }

        img {
            width: 100%;
        }

        &:nth-of-type(2) {
            transition-duration: $duration * 7;
            transition-delay: $duration *2;
        }

        &:nth-of-type(3) {
            transition-duration: $duration * 6;
            transition-delay: $duration *3;
        }

        &:nth-of-type(4) {
            transition-duration: $duration * 5;
            transition-delay: $duration *4;
        }

        &:nth-of-type(5) {
            transition-duration: $duration * 4;
            transition-delay: $duration *5;
        }

        &:nth-of-type(6) {
            transition-duration: $duration * 3;
            transition-delay: $duration * 6;
        }

        &:nth-of-type(7) {
            transition-duration: $duration * 2;
            transition-delay: $duration * 7;

        }

        &:nth-of-type(8) {
            transition-duration: $duration;
            transition-delay: $duration * 8;

        }
    }

    &.expanded {
        div.floor {
            $height: 10.5vh;
            top: $height * 7;
            transition-duration: $duration * 7;
            transition-delay: 0s;

            &:nth-of-type(1) {
                opacity: 0;
            }

            &:nth-of-type(2) {
                top: $height * 7;
                transition-duration: $duration * 7;
            }

            &:nth-of-type(3) {
                top: $height * 6;
                transition-duration: $duration * 6;
            }

            &:nth-of-type(4) {
                top: $height * 5;
                transition-duration: $duration * 5;
            }

            &:nth-of-type(5) {
                top: $height * 4;
                transition-duration: $duration * 4;
            }

            &:nth-of-type(6) {
                top: $height * 3;
                transition-duration: $duration * 3;
            }

            &:nth-of-type(7) {
                top: $height * 2;
                transition-duration: $duration * 2;
            }

            &:nth-of-type(8) {
                top: $height;
                transition-duration: $duration;
            }

            &:nth-of-type(9) {
                top: 0;
            }
        }
    }
}
